import { init } from "next-firebase-auth"
import { initializeApp } from "firebase/app";

const initAuth = () => {
  let firebaseClientInitConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string, // required
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
  }
  init({
    authPageURL: "/admin/login",
    appPageURL: "/admin",
    loginAPIEndpoint: "/api/admin/login",
    logoutAPIEndpoint: "/api/admin/logout",

    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
        clientEmail: process.env.FIREBASE_ADMIN_CLIENT_EMAIL as string,
        // The private key must not be accesssible on the client side.
        privateKey: process.env.FIREBASE_ADMIN_PRIVATE_KEY as string,
      },
      databaseURL: "",
    },
    firebaseClientInitConfig,
    cookies: {
      name: process.env.APP_NAME as string, // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    },
  })

  initializeApp(firebaseClientInitConfig)
}

export { initAuth }
