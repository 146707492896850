import type { AppProps } from "next/app"
import { AppContextProvider } from "../lib/app-context"
import { appWithTranslation } from "next-i18next"
import "../styles/tailwind.css"
import { initAuth } from "../lib/init-auth"
import TagManager from "react-gtm-module"
import { useEffect } from "react"

initAuth()

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID as string })
    }
  }, [])

  return (
    <AppContextProvider>
      <Component {...pageProps} />
    </AppContextProvider>
  )
}
export default appWithTranslation(MyApp)
